import { render, staticRenderFns } from "./MongoDB.vue?vue&type=template&id=249150dc&scoped=true&"
import script from "./MongoDB.vue?vue&type=script&lang=js&"
export * from "./MongoDB.vue?vue&type=script&lang=js&"
import style0 from "./MongoDB.vue?vue&type=style&index=0&id=249150dc&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249150dc",
  null
  
)

export default component.exports