<template>
  <section class="yml">
    <div class="f mb-20 w">
      <div>
        <h1>MongoDB</h1>
        <p>Инструкция как настроить MongoDB</p>
      </div>
      <a class="btn" target="_blank" href="https://account.mongodb.com/account/login">Открыть облачную MongoDB</a>
    </div>
    <div class="fields w">
      <div class="form-control">
        <label>IP Address</label>
        <input v-model="ip" />
      </div>
      <div class="form-control">
        <label>Username</label>
        <input spellcheck="false" v-model="username" />
      </div>
    </div>
    <div v-for="(commandNodes, cN) of commands" :key="cN" class="action_result" tabindex="0">
      <template v-for="(node, n) of commandNodes">
        <pre :key="`${n}-${node.type}`" v-if="node.type === 'code'" v-highlightjs="node.content"><code class="bash"></code></pre>
        <p :key="`${n}-${node.type}`" v-if="node.type === 'text'" v-html="node.content" />
      </template>
    </div>
    <p class="next">Далее можно перейти к <router-link to="/services/nginx">настройке доменов</router-link></p>
  </section>
</template>

<script>
export default {
  name: 'Deployment',
  data() {
    return {
      ip: '185.185.68.125',
      username: 'yablonev',
      personalIP: '',
    }
  },
  computed: {
    commands() {
      return [
        [
          { type: 'code', content: `ssh ${this.username}@${this.ip}` },
        ],
        [
          { type: 'code', content: 'wget -qO - https://www.mongodb.org/static/pgp/server-6.0.asc | sudo apt-key add -' },
        ],
        [
          { type: 'code', content: 'echo "deb [ arch=amd64,arm64 ] https://repo.mongodb.org/apt/ubuntu bionic/mongodb-org/6.0 multiverse" | sudo tee /etc/apt/sources.list.d/mongodb-org-6.0.list' },
        ],
        [
          { type: 'code', content: 'sudo apt-get update' },
        ],
        [
          { type: 'code', content: 'sudo apt-get install -y mongodb-org' },
        ],
        [
          { type: 'code', content: 'sudo systemctl start mongod' },
          { type: 'text', content: 'Включаем MongoDB' },
        ],
        [
          { type: 'code', content: 'sudo systemctl status mongod' },
          { type: 'text', content: 'Проверяем статус MongoDB' },
        ],
        [
          { type: 'code', content: 'sudo systemctl enable mongod' },
          { type: 'text', content: 'Ставим MongoDB в автозапуск' },
        ],
        [
          { type: 'code', content: 'sudo nano /etc/mongod.conf' },
          { type: 'text', content: 'Туда ставим ↓' },
          { type: 'code', content: `net:
  port: 27777
  bindIp: ${this.ip}` }
        ],
        [
          { type: 'code', content: 'sudo systemctl restart mongod' },
          { type: 'text', content: 'Перезапускаем MongoDB' },
        ],
        [
          { type: 'code', content: `sudo ufw allow from ${this.personalIP}` },
          { type: 'text', content: 'Разрешаем доступ со своего IP' },
        ],
        [
          { type: 'code', content: `mongodb://${this.ip}:27777` },
          { type: 'text', content: 'Подключаемся в MongoDB Compass и ставим на бекенд PROD_DB_URL в конфиг' },
        ]
      ]
    }
  },
  methods: {
    async getIP() {
      const data = await fetch('https://api.ipify.org?format=json')
      const json = await data.json()
      return json.ip
    }
  },
  async mounted() {
    this.personalIP = await this.getIP()
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/nginx'

.action_result:focus, .action_result:focus-visible
  box-shadow: inset 0 0 8px 2px #000000c2
  border: 0

.action_result p
  color: #4dbf90
  font-size: 14px
  line-height: 28px
  padding-left: 0.3em

p.next
  display: flex

  a
    color: #55657e
    padding-left: 4px
    font-weight: 500
</style>